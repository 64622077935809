import { OverlayScrollbars } from "overlayscrollbars";
import "overlayscrollbars/overlayscrollbars.css";

document.addEventListener("DOMContentLoaded", function () {
  OverlayScrollbars(document.body, {
    scrollbars: {
      autoHide: "never",
    },
  });

  OverlayScrollbars(document.querySelector(".how-it-works__content"), {
    overflowBehavior: {
      x: "scroll",
      y: "hidden",
    },
    scrollbars: {
      autoHide: "never",
    },
  });

  const modalWrappers = document.querySelectorAll(".modal__content");

  modalWrappers.forEach((wrapper) => {
    OverlayScrollbars(wrapper, {
      scrollbars: {
        autoHide: "leave",
      },
    });
  });

  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  };
  window.addEventListener("resize", appHeight);
  window.addEventListener("scroll", appHeight);
  appHeight();

  window.onload = () => {
    const videos = document.querySelectorAll(".hero-main  video");

    for (const video of videos) {
      video.setAttribute("playsinline", "");
      video.setAttribute("muted", "");
      video
        .play()
        .then(() => {})
        .catch((error) => {
          video.play();
        });
    }
  };
});
